import React from 'react'
import PagePricing from '../components/pages/PricingEcommerce/PricingEcommerce'
import Layout from '../Layouts/en'

const pricingEn = ({ location }) => {
  return (
    <Layout location={location}>
      <PagePricing />
    </Layout>
  )
}

export default pricingEn
